export default function Events() {
  const t = useI18n()
  return (
    <div className='mt-2 md:container mb-5 md:mb-10'>
      <div className='text-[1.2rem] md:text-[1.5rem] font-medium py-10 text-center md:text-left'>尋找這一次的優質服務</div>
      <div className='space-y-12 flex flex-col sm:px-3 md:px-0'>
        <Block
          src={ cdn('next/referral-code/sinliya.jpg') }
          href={ h.path.studioEvents() }
          t={ t }
          title={ t('common:mutualBenefit.title') }
          paragraph={ t('common:mutualBenefit.description') }
        />
      </div>
    </div>
  )
}

const Block = ({ src, href, title, paragraph, t }) => (
  <div className='flex-1 block flex sm:space-x-6 flex-col sm:flex-row lg:space-x-8'>
    <div className='cursor-pointer'>
      <C.Link href={ href }>
        <C.Image
          className='aspect-w-16 aspect-h-9 shadow-md sm:min-w-[20rem] lg:min-w-[25rem]'
          src={ src }
        />
      </C.Link>
    </div>
    <div className='px-6 sm:px-0 py-3 lg:mt-3'>
      <div className='py-3 lg:py-4'>
        <div className='text-xl sm:text-[1.3rem] md:text-2xl font-medium'>{ title }</div>
      </div>
      <p className='font-light' dangerouslySetInnerHTML={ { __html: paragraph } }/>
      <div className='mt-3'
      >
        <C.Link href={ href }>
          <C.Button>
            { t('common:readMore') }
          </C.Button>
        </C.Link>
      </div>
    </div>
  </div>
)