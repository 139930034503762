import StarRateIcon from '@mui/icons-material/StarRate'

export default function EventRow({ stylist, userBookingDic, bookingSuccessCallback, updateCallback, serviceCategory }) {
  const t = useI18n()
  const { user, usedQuota, services, studio, minDiscountRatio } = stylist
  const { btnDisable, tags, btnText, currentDiscount, otherDiscountDic } = discountResolver(user.id, userBookingDic, usedQuota, services, serviceCategory, minDiscountRatio, t)
  const remainQuotaLabel = peopleResolver(usedQuota)
  const { originPrice, price, labelText } = currentDiscount
  // const openReviewClick = () => h.reviewOpen({
  //   type: 'stylist',
  //   id: user.id
  // })
  // const openCollectionClick = () => h.openCollection({
  //   type: 'user',
  //   id: user.id,
  //   title: user.name
  // })
  // const openStudioClick = () => h.openStylistEventStudio({ studioId: studio.id })

  const openStylistClick = () => h.openEventStylist({
    userId: user.id,
    studioId: studio.id,
    btnDisable,
    btnText,
    labelText,
    remainQuotaLabel,
    currentDiscount,
    otherDiscountDic,
    callback: bookingSuccessCallback,
    updateCallback
  })
  // const openBookingClick = () => h.openStylistEventBooking({
  //   stylistId: stylist.id,
  //   callback: bookingSuccessCallback,
  //   updateCallback
  // })

  return (
    <div className='hover:bg-gray-100 cursor-pointer' onClick={ openStylistClick }>
      <div className='container flex pb-4 flex-wrap'>
        <div className='flex-1'>
          <div className='flex flex-wrap'>

            <div className='shrink-0 pt-5 md:mt-1'>
              <C.Image
                src={ stylist.user.avatar }
                className='w-16 h-16 mr-1 md:mr-2 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full'
              />
            </div>

            <div className='flex-1 space-y-[0.15rem] pt-5 px-2 md:space-y-1 lg:space-y-2'>

              <div>
                <div className='flex items-center justify-between space-x-1 md:justify-start md:space-x-2'>
                  <div className='font-medium md:mt-2 lg:mt-4'>
                    { stylist.user.name }
                  </div>
                  { !!labelText && (
                    <div className='py-1 px-2 tracking-widest text-white bg-gray-600 rounded-[4px] text-xs md:mt-2 lg:mt-4'>
                      { labelText }
                    </div>
                  ) }
                </div>
                <div className='text-sm tracking-wider text-gray-700 line-clamp-1 pb-1 md:pb-0 pt-1 lg:pt-2'>
                  { studio.address }
                </div>

              </div>

              <div className='flex md:flex-wrap'>
                <div className='md:w-full'>
                  <div className='shrink-0 flex items-center text-gray-700 space-x-1 mr-2'>

                    <span className='text-yellow-400 -translate-y-0.5 text-sm'>
                      <StarRateIcon fontSize='inherit' />
                    </span>
                    <span className='text-xs'>
                      { stylist.$rating } ({ t('page:userId.ratingCount', { count: stylist.$ratingCount }) })
                    </span>
                    {/* <C.Stars score={ stylist.rating } className='w-4 h-4'/>
                  <div>{ t('page:stylists.ratingCount', { count: stylist.totalRatingCount.format() }) }</div> */}
                  </div>
                </div>

                <div className='flex items-center md:mt-2 tracking-wider'>
                  <div className='text-cyan-800 text-[1.05rem] font-medium hidden md:block'>NT${ price }</div>
                  <div className='text-gray-400 line-through ml-2 text-sm hidden md:block'>${ originPrice }</div>
                </div>
              </div>
              <div className='hidden md:block'>
                {/* <C.Button className='w-[6.2rem]' disabled={ btnDisable } onClick={ openBookingClick }>{ btnText }</C.Button> */}
                <div className='text-xs flex flex-wrap items-center tracking-widest'>
                  { tags.map(tag => (
                    <div key={ tag } className='py-1 px-2 mr-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                      { tag }
                    </div>
                  )) }

                  {/* <div className='py-1 px-2 mr-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                    { tag }
                  </div>
                  <div className='py-1 px-2 mr-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                    { remainQuotaLabel }
                  </div> */}
                </div>
              </div>
            </div>

          </div>
          <div className='px-1 pt-1 flex items-center md:hidden'>
            <div className='flex items-center flex-1 tracking-wider'>
              <div className='text-cyan-800 font-medium line-clamp-1'>NT${ price }</div>
              <div className='text-gray-400 line-through text-sm ml-1 line-clamp-1'>${ originPrice }</div>
            </div>

            <div className='text-xs flex flex-wrap items-center tracking-widest'>
              { tags.map(tag => (
                <div key={ tag } className='py-1 px-2 ml-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                  { tag }
                </div>
              )) }
              {/* <div className='py-1 px-2 ml-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                { tag }
              </div>
              <div className='py-1 px-2 ml-2 bg-gray-100 text-gray-500 rounded-[4px]'>
                { remainQuotaLabel }
              </div> */}
            </div>

          </div>
        </div>

        <div className='w-full md:w-1/2 shrink-0 py-3 flex'>
          { stylist.customCovers.map(cover => (
            <div key={ cover.postId } className='w-1/3 px-1 lg:px-2'>
              <div>
                <C.Image
                  src={ cover.coverUrl }
                  className='pt-[133%] rounded-[5px] shadow'
                />
              </div>
            </div>
          )) }
        </div>
      </div>
    </div>
  )

}

const peopleResolver = () =>
  // if (usedQuota < 3) return `剩餘${3 - usedQuota}個名額`
  // if (usedQuota < 6) return `剩餘${6 - usedQuota}個名額`
  // return `兩週內${usedQuota}人報名`
  ''

const categoryResolver = (category, ratio) => {
  switch (category) {
    case 'haircut':
      return `洗剪${ratio * 10}折`
    case 'dye':
    case 'perm':
      return `染燙${ratio * 10}折`
    case 'nail':
      return `美甲${ratio * 10}折`
    default:
      return ''
  }
}

const discountResolver = (userId, userBookingDic, usedQuota, services, serviceCategory, minDiscountRatio, t) => {
  const discountRuleDic = store.stylistEventRuleDic
  if (!userBookingDic) return ratioResolver(discountRuleDic.newUser, peopleResolver(usedQuota), usedQuota, services, serviceCategory, minDiscountRatio, t)
  const newCustomerDic = userBookingDic[userId]
  if (!newCustomerDic) return ratioResolver(discountRuleDic.newCustomer, peopleResolver(usedQuota), usedQuota, services, serviceCategory, minDiscountRatio, t)
  if (newCustomerDic[2]) {
    const { tags, currentDiscount, otherDiscountDic } = ratioResolver(discountRuleDic.newCustomer, '已體驗', usedQuota, services, serviceCategory, minDiscountRatio, t)
    return { btnDisable: true, tags, btnText: '已體驗', currentDiscount, otherDiscountDic }
  }
  const { tags, currentDiscount, otherDiscountDic } = ratioResolver(discountRuleDic.newCustomer, '已預約', usedQuota, services, serviceCategory, minDiscountRatio, t)
  return { btnDisable: true, tags, btnText: '已預約', currentDiscount, otherDiscountDic }
}

const ratioResolver = (rules, labelText, usedQuota, services, serviceCategory, minDiscountRatio, t) => {
  let currentDiscount = null
  const otherDiscountDic = {}
  let tags = []

  for (let i = 0; i < rules.length; i += 1) {
    const { quota, haircutRatio, othersRatio } = rules[i]
    if (usedQuota >= quota) continue

    tags = []//[`洗剪${haircutRatio * 10}折`, `染燙${othersRatio * 10}折`, `美甲${othersRatio * 10}折`]
    services.forEach(({ service, minPrice, maxPrice }) => {
      const category = store.stylistEventServiceIdDic[service.id]
      if (!category) return

      const ratio = Math.max(category === 'haircut' ? haircutRatio : othersRatio, minDiscountRatio)
      const minDiscountPrice = Math.ceil(minPrice * ratio)
      const maxDiscountPrice = Math.ceil(maxPrice * ratio)

      const tagName = t(`common:service.category.${category}`)
      const tag = `${tagName}${ratio * 10}折`
      const originPrice = priceResolver(minPrice, maxPrice)
      const price = priceResolver(minDiscountPrice, maxDiscountPrice)

      const originalRange = `${ t('common:originalPrice') }: ${ getPriceRangeText(minPrice, maxPrice, t) }`
      const range = getPriceRangeText(minDiscountPrice, maxDiscountPrice, t)
      const discountRatio = `${ ratio * 10 }${ t('common:discount.discountType.priceOff') }`
      const title = service.name

      const categoryTag = categoryResolver(category, ratio)
      if (!tags.includes(categoryTag)) tags.push(categoryTag)

      const discount = { tag, originPrice, price, labelText, title, originalRange, range, discountRatio }
      if (category === serviceCategory) {
        currentDiscount = discount
        return
      }
      otherDiscountDic[category] = discount
    })

    if (!currentDiscount) break
    return { btnDisable: false, tags, btnText: '立即報名', currentDiscount, otherDiscountDic }
  }

  currentDiscount = {
    tag: '未開放',
    originPrice: 'NA',
    price: 'NA',
    labelText
  }
  return { btnDisable: true, tags, btnText: '未開放', currentDiscount, otherDiscountDic }
}

const priceResolver = (minPrice, maxPrice) => {
  if (minPrice < 0) return maxPrice.format()
  const price = minPrice.format()
  if (minPrice !== maxPrice) return `${price}起`
  return price
}