const template = `
query UserBookingRecords {
    userBookingRecords {
      state
      stylistUserId
    }
  }
`

export default async function userBookingRecords() {
  const { userBookingRecords } = await request(template)
  return userBookingRecords
}
