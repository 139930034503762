import Page from 'components/pages/root'
import initRequest from 'request/root/init'

export async function getStaticProps() {
  const initData = await initRequest()
  return { props: initData, revalidate: 60 * 10 }
}

export default function Root(initData) {
  const t = useI18n()
  const title = t.seo.title('root')
  const description = t('seo:root.description')
  const url = h.path.full.root()
  return <>
    <C.Head>
      { h.seo({ title, description }) }
      { h.ldjson({
        '@context': 'http://schema.org/',
        '@type': 'WebSite',
        'url': url,
        'name': 'StyleMap',
        'author': {
          'sameAs': [
            'https://www.facebook.com/stylemappage',
            'https://www.instagram.com/stylemap_official',
            'https://www.youtube.com/channel/UC1aaEXUQV-HWYokTNAejL-A'
          ],
          '@type': 'Organization'
        }
      }) }
      { h.ldjson({
        '@context': 'http://schema.org/',
        '@type': 'FAQPage',
        '@id': url,
        'headline': title,
        'mainEntity': [
          {
            '@type': 'Question',
            'name': t('seo:faq.question1'),
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': t('seo:faq.answer1', { url })
            }
          },
          {
            '@type': 'Question',
            'name': t('seo:faq.question2'),
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': t('seo:faq.answer2', { url })
            }
          }
        ]
      }) }
    </C.Head>
    <Page initData={ initData }/>
  </>
}
