export default function Hero({ title, subtitle, src, children, center, type = 'cover' }) {
  return (
    <div className='md:h-screen max-h-[30rem] relative'>
      <div className='absolute inset-0 -z-1'>
        <C.Image
          className='w-full h-full'
          type={ type }
          src={ src }
        />
      </div>
      <div className='bg-black/20 h-full'>
        <div className='container flex h-full items-center'>
          <div className='flex-1 text-white'>
            <div className='py-24 text-center text-shadow-lg'>
              <div className='text-2xl pb-2 md:text-3xl'>{ title }</div>
              <div className='text-2xl md:text-3xl'>{ subtitle }</div>
              <div className={ cn('translate-y-8', { hidden: !center }) }>
                { children }
              </div>
            </div>
          </div>
          { !center && children }
        </div>
      </div>
    </div>
  )
}
