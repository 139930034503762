import CommonHero from 'components/common/hero'

export default function Hero() {
  const t = useI18n()

  return (
    <CommonHero
      src={ cdn('next/hero/root-1.jpg') }
      title={ t('page:referralCode.hero.title') }
      subtitle={ t('page:referralCode.hero.subtitle') }
    />
  )
}
